var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "widget_name", {"start":{"line":1,"column":11},"end":{"line":1,"column":22}} ), depth0))
    + "_widget_wrapper\" tabindex=\"0\">\n    <div class=\"stream_header_colorblock\"></div>\n    "
    + ((stack1 = container.invokePartial(require("./dropdown_widget.hbs"),depth0,{"name":"dropdown_widget","hash":{"disable_keyboard_focus":"true"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});