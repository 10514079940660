var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " private-message";
},"3":function(container,depth0,helpers,partials,data) {
    return " include-sender";
},"5":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(container.lambda(container.strict(depth0, "mention_classname", {"start":{"line":2,"column":153},"end":{"line":2,"column":170}} ), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    return " unread";
},"9":function(container,depth0,helpers,partials,data) {
    return "locally-echoed";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"unread_marker date_unread_marker\"><div class=\"unread-marker-fill\"></div></div>\n    <div class=\"date_row no-select\">\n        "
    + ((stack1 = container.lambda(container.strict(depth0, "date_divider_html", {"start":{"line":7,"column":11},"end":{"line":7,"column":28}} ), depth0)) != null ? stack1 : "")
    + "\n    </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "is-me-message";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), alias5=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div zid=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"msg"), "id", {"start":{"line":1,"column":12},"end":{"line":1,"column":18}} ), depth0))
    + "\" id=\""
    + alias3(alias2(alias1(depth0, "table_name", {"start":{"line":1,"column":28},"end":{"line":1,"column":38}} ), depth0))
    + alias3(alias2(alias1(lookupProperty(depth0,"msg"), "id", {"start":{"line":1,"column":42},"end":{"line":1,"column":48}} ), depth0))
    + "\"\n  class=\"message_row"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(lookupProperty(depth0,"msg"),"is_stream"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":72}}})) != null ? stack1 : "")
    + ((stack1 = alias5.call(depth0,alias2(alias1(depth0, "include_sender", {"start":{"line":2,"column":75},"end":{"line":2,"column":89}} ), depth0),{"name":"include_sender","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":72},"end":{"line":2,"column":125}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"mention_classname"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":125},"end":{"line":2,"column":179}}})) != null ? stack1 : "")
    + ((stack1 = alias5.call(depth0,alias2(alias1(lookupProperty(depth0,"msg"), "unread", {"start":{"line":2,"column":182},"end":{"line":2,"column":192}} ), depth0),{"name":"msg.unread","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":179},"end":{"line":2,"column":216}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(lookupProperty(depth0,"msg"),"locally_echoed"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":217},"end":{"line":2,"column":264}}})) != null ? stack1 : "")
    + " selectable_row\"\n  role=\"listitem\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"want_date_divider"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"unread_marker message_unread_marker\"><div class=\"unread-marker-fill\"></div></div>\n    <div class=\"messagebox\">\n        <div class=\"messagebox-content "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"status_message"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":81}}})) != null ? stack1 : "")
    + "\">\n            "
    + ((stack1 = container.invokePartial(require("./message_body.hbs"),depth0,{"name":"message_body","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});