var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-group-id=\""
    + alias4((((helper = lookupProperty(helpers,"group_id") || alias1(depth0, "group_id", {"start":{"line":1,"column":21},"end":{"line":1,"column":29}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"group_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":31}}}) : helper)))
    + "\">\n    <td class=\"group_list_item\">\n        "
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":3,"column":10},"end":{"line":3,"column":14}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":16}}}) : helper)))
    + "\n    </td>\n</tr>\n";
},"useData":true});