var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"new_message_button new_direct_message_button_container\">\n                <button type=\"button\" class=\"button small rounded compose_new_direct_message_button\"\n                  id=\"new_direct_message_button\"\n                  data-tooltip-template-id=\"new_direct_message_button_tooltip_template\">\n                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"New direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":39,"column":47}}}))
    + "\n                </button>\n            </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"compose-content\">\n    <div id=\"scroll-to-bottom-button-container\" aria-hidden=\"true\">\n        <div id=\"scroll-to-bottom-button-clickable-area\" data-tooltip-template-id=\"scroll-to-bottom-button-tooltip-template\">\n            <div id=\"scroll-to-bottom-button\">\n                <i class=\"fa fa-chevron-down\"></i>\n            </div>\n        </div>\n    </div>\n    <div id=\"compose_controls\" class=\"new-style\">\n        <div id=\"compose_buttons\">\n            <div class=\"new_message_button reply_button_container\">\n                <button type=\"button\" class=\"button small compose_reply_button\"\n                  id=\"left_bar_compose_reply_button_big\"\n                  data-tooltip-template-id=\"compose_reply_message_button_tooltip_template\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Compose message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":44}}}))
    + "\n                </button>\n                <button type=\"button\" class=\"button compose_new_conversation_button\"\n                  id=\"new_conversation_button\"\n                  data-tooltip-template-id=\"new_stream_message_button_tooltip_template\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Start new conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":51}}}))
    + "\n                </button>\n            </div>\n            <span class=\"new_message_button mobile_button_container\">\n                <button type=\"button\" class=\"button small rounded compose_mobile_button\"\n                  id=\"left_bar_compose_mobile_button_big\"\n                  data-tooltip-template-id=\"left_bar_compose_mobile_button_tooltip_template\">\n                    +\n                </button>\n            </span>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"embedded"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":42,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"message_comp\">\n        <div id=\"compose_banners\" data-simplebar></div>\n        <div class=\"composition-area\">\n            <form id=\"send_message_form\" action=\"/json/messages\" method=\"post\">\n                <div class=\"compose_table\">\n                    <div id=\"compose_top\">\n                        <div id=\"compose_top_right\" class=\"order-2\">\n                            <button type=\"button\" class=\"expand_composebox_button fa fa-chevron-up\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expand compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":112},"end":{"line":52,"column":135}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expand compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":157},"end":{"line":52,"column":180}}}))
    + "\"></button>\n                            <button type=\"button\" class=\"collapse_composebox_button fa fa-chevron-down\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":116},"end":{"line":53,"column":141}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":163},"end":{"line":53,"column":188}}}))
    + "\"></button>\n                            <button type=\"button\" class=\"close fa fa-times\" id='compose_close' data-tooltip-template-id=\"compose_close_tooltip_template\"></button>\n                        </div>\n                        <div id=\"compose-recipient\" class=\"order-1\">\n                            <div class=\"topic-marker-container order-1\">\n                                <a role=\"button\" class=\"narrow_to_compose_recipients zulip-icon zulip-icon-arrow-left-circle\" data-tooltip-template-id=\"narrow_to_compose_recipients_tooltip\"></a>\n                            </div>\n                            "
    + ((stack1 = container.invokePartial(require("./dropdown_widget_with_stream_colorblock.hbs"),depth0,{"name":"dropdown_widget_with_stream_colorblock","hash":{"widget_name":"compose_select_recipient"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            <div class=\"topic-marker-container\">\n                                <i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>\n                            </div>\n                            <div id=\"compose_recipient_box\">\n                                <input type=\"text\" name=\"stream_message_recipient_topic\" id=\"stream_message_recipient_topic\" maxlength=\""
    + alias2(container.lambda(container.strict(depth0, "max_topic_length", {"start":{"line":66,"column":139},"end":{"line":66,"column":155}} ), depth0))
    + "\" value=\"\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":182},"end":{"line":66,"column":196}}}))
    + "\" autocomplete=\"off\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":242},"end":{"line":66,"column":256}}}))
    + "\" />\n                                <button type=\"button\" id=\"recipient_box_clear_topic_button\" class=\"button tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":155},"end":{"line":67,"column":175}}}))
    + "\" tabindex=\"-1\">\n                                    <i class=\"zulip-icon zulip-icon-close\"></i>\n                                </button>\n                            </div>\n                            <div id=\"compose-direct-recipient\" class=\"pill-container\" data-before=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":99},"end":{"line":71,"column":115}}}))
    + "\">\n                                <div class=\"input\" contenteditable=\"true\" id=\"private_message_recipient\" data-no-recipients-text=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add one or more users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":130},"end":{"line":72,"column":160}}}))
    + "\" data-some-recipients-text=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add another user...",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":189},"end":{"line":72,"column":217}}}))
    + "\"></div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"messagebox-wrapper\">\n                        <div class=\"messagebox\">\n                            <textarea class=\"new_message_textarea\" name=\"content\" id='compose-textarea' placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Compose your message here",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":117},"end":{"line":78,"column":151}}}))
    + "\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Compose your message here...",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":178},"end":{"line":78,"column":215}}}))
    + "\"></textarea>\n                            <div id=\"preview-message-area-container\">\n                                <div class=\"scrolling_list preview_message_area\" data-simplebar id=\"preview_message_area\" style=\"display:none;\">\n                                    <div class=\"markdown_preview_spinner\"></div>\n                                    <div class=\"preview_content rendered_markdown\"></div>\n                                </div>\n                            </div>\n                            <div class=\"drag\"></div>\n\n                            <div id=\"message-send-controls-container\">\n                                <a id=\"compose-drafts-button\" role=\"button\" class=\"send-control-button hide-sm\" tabindex=0 href=\"#drafts\" data-tooltip-template-id=\"compose_draft_tooltip_template\">\n                                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":36},"end":{"line":89,"column":51}}}))
    + "\n                                </a>\n                                <span id=\"compose-limit-indicator\"></span>\n                                <div class=\"message-send-controls\">\n                                    <button type=\"submit\" id=\"compose-send-button\" class=\"send_message compose-submit-button compose-send-or-save-button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":166},"end":{"line":93,"column":179}}}))
    + "\">\n                                        <img class=\"loader\" alt=\"\" src=\"\" />\n                                        <i class=\"zulip-icon zulip-icon-send\"></i>\n                                    </button>\n                                    <button class=\"send-control-button send-related-action-button\" id=\"send_later\" tabindex=0 type=\"button\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send options",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":160},"end":{"line":97,"column":181}}}))
    + "\">\n                                        <i class=\"zulip-icon zulip-icon-more-vertical\"></i>\n                                    </button>\n                                </div>\n                            </div>\n\n                            <div id=\"message-formatting-controls-container\">\n                                "
    + ((stack1 = container.invokePartial(require("./compose_control_buttons.hbs"),depth0,{"name":"compose_control_buttons","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n                </div>\n            </form>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});