var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <textarea maxlength=\"500\" class=\"custom_user_field_value settings_textarea\">"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"field_value"), "value", {"start":{"line":7,"column":87},"end":{"line":7,"column":104}} ), depth0))
    + "</textarea>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_select_field"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <select class=\"custom_user_field_value "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":47},"end":{"line":9,"column":118}}})) != null ? stack1 : "")
    + " bootstrap-focus-style\">\n            <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"field_choices"),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "modal_select";
},"7":function(container,depth0,helpers,partials,data) {
    return "settings_select";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":12,"column":34},"end":{"line":12,"column":44}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"selected"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":85}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":12,"column":89},"end":{"line":12,"column":98}} ), depth0))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "selected";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_user_field"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"pill-container person_picker\">\n            <div class=\"input\" contenteditable=\"true\"></div>\n        </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_date_field"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value datepicker "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":20,"column":57},"end":{"line":20,"column":136}}})) != null ? stack1 : "")
    + "\" data-field-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "id", {"start":{"line":20,"column":156},"end":{"line":20,"column":164}} ), depth0))
    + "\" type=\"text\"\n          value=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field_value"), "value", {"start":{"line":21,"column":20},"end":{"line":21,"column":37}} ), depth0))
    + "\" />\n        <span class=\"remove_date\"><i class=\"fa fa-close\"></i></span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "modal_text_input";
},"19":function(container,depth0,helpers,partials,data) {
    return "settings_text_input";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_url_field"),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":24,"column":46},"end":{"line":24,"column":123}}})) != null ? stack1 : "")
    + "\" type=\""
    + alias3((((helper = lookupProperty(helpers,"field_type") || alias2(depth0, "field_type", {"start":{"line":24,"column":134},"end":{"line":24,"column":144}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"field_type","hash":{},"data":data,"loc":{"start":{"line":24,"column":131},"end":{"line":24,"column":147}}}) : helper)))
    + "\" value=\""
    + alias3(container.lambda(alias2(lookupProperty(depth0,"field_value"), "value", {"start":{"line":24,"column":159},"end":{"line":24,"column":176}} ), depth0))
    + "\" maxlength=\"2048\" />\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "modal_url_input";
},"25":function(container,depth0,helpers,partials,data) {
    return "settings_url_input";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_pronouns_field"),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value pronouns_type_field "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":26,"column":66},"end":{"line":26,"column":145}}})) != null ? stack1 : "")
    + "\" type=\""
    + alias3((((helper = lookupProperty(helpers,"field_type") || alias2(depth0, "field_type", {"start":{"line":26,"column":156},"end":{"line":26,"column":166}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"field_type","hash":{},"data":data,"loc":{"start":{"line":26,"column":153},"end":{"line":26,"column":169}}}) : helper)))
    + "\" value=\""
    + alias3(container.lambda(alias2(lookupProperty(depth0,"field_value"), "value", {"start":{"line":26,"column":181},"end":{"line":26,"column":198}} ), depth0))
    + "\" maxlength=\"50\" />\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_manage_user_modal"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":28,"column":46},"end":{"line":28,"column":125}}})) != null ? stack1 : "")
    + "\" type=\""
    + alias3((((helper = lookupProperty(helpers,"field_type") || alias2(depth0, "field_type", {"start":{"line":28,"column":136},"end":{"line":28,"column":146}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"field_type","hash":{},"data":data,"loc":{"start":{"line":28,"column":133},"end":{"line":28,"column":149}}}) : helper)))
    + "\" value=\""
    + alias3(container.lambda(alias2(lookupProperty(depth0,"field_value"), "value", {"start":{"line":28,"column":161},"end":{"line":28,"column":178}} ), depth0))
    + "\" maxlength=\"50\" />\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"custom_user_field\" name=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "name", {"start":{"line":1,"column":40},"end":{"line":1,"column":50}} ), depth0))
    + "\" data-field-id=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "id", {"start":{"line":1,"column":73},"end":{"line":1,"column":81}} ), depth0))
    + "\">\n    <label class=\"inline-block\" for=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "name", {"start":{"line":2,"column":40},"end":{"line":2,"column":50}} ), depth0))
    + "\" class=\"title\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "name", {"start":{"line":2,"column":72},"end":{"line":2,"column":82}} ), depth0))
    + "</label>\n    <div class=\"alert-notification custom-field-status\"></div>\n    <div class=\"field_hint\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"field"), "hint", {"start":{"line":4,"column":31},"end":{"line":4,"column":41}} ), depth0))
    + "</div>\n    <div class=\"field\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_long_text_field"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});