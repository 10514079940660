var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"profile-field-row movable-row\" data-profile-field-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":2,"column":67},"end":{"line":2,"column":69}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":65},"end":{"line":2,"column":71}}}) : helper)))
    + "\">\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depths[1],"can_modify"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"profile_field_name\">"
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":8,"column":43},"end":{"line":8,"column":47}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"profile_field_hint\">"
    + alias4((((helper = lookupProperty(helpers,"hint") || alias1(depth0, "hint", {"start":{"line":11,"column":43},"end":{"line":11,"column":47}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"hint","hash":{},"data":data,"loc":{"start":{"line":11,"column":41},"end":{"line":11,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"profile_field_type\">"
    + alias4((((helper = lookupProperty(helpers,"type") || alias1(depth0, "type", {"start":{"line":14,"column":43},"end":{"line":14,"column":47}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td class=\"display_in_profile_summary_cell\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"valid_to_display_in_summary"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depths[1],"can_modify"),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":35,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n        <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"profile_field_display_in_profile_summary\">\n            <label class=\"checkbox display_in_profile_summary_"
    + alias4((((helper = lookupProperty(helpers,"display_in_profile_summary") || alias1(depth0, "display_in_profile_summary", {"start":{"line":19,"column":64},"end":{"line":19,"column":90}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"display_in_profile_summary","hash":{},"data":data,"loc":{"start":{"line":19,"column":62},"end":{"line":19,"column":92}}}) : helper)))
    + "\" for=\"profile_field_display_in_profile_summary_"
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":19,"column":142},"end":{"line":19,"column":144}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":19,"column":140},"end":{"line":19,"column":146}}}) : helper)))
    + "\">\n                <input class=\"display_in_profile_summary display_in_profile_summary_checkbox_"
    + alias4((((helper = lookupProperty(helpers,"display_in_profile_summary") || alias1(depth0, "display_in_profile_summary", {"start":{"line":20,"column":95},"end":{"line":20,"column":121}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"display_in_profile_summary","hash":{},"data":data,"loc":{"start":{"line":20,"column":93},"end":{"line":20,"column":123}}}) : helper)))
    + "\" type=\"checkbox\" id=\"profile_field_display_in_profile_summary_"
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":20,"column":188},"end":{"line":20,"column":190}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":186},"end":{"line":20,"column":192}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"display_in_profile_summary"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":194},"end":{"line":20,"column":254}}})) != null ? stack1 : "")
    + " data-profile-field-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":20,"column":280},"end":{"line":20,"column":282}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":278},"end":{"line":20,"column":284}}}) : helper)))
    + "\"/>\n                <span></span>\n            </label>\n        </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"actions\">\n        <button class=\"button rounded small btn-warning open-edit-form-modal\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":85},"end":{"line":28,"column":98}}}))
    + "\" data-profile-field-id=\""
    + alias2((((helper = lookupProperty(helpers,"id") || alias3(depth0, "id", {"start":{"line":28,"column":125},"end":{"line":28,"column":127}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":28,"column":123},"end":{"line":28,"column":129}}}) : helper)))
    + "\">\n            <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n        </button>\n        <button class=\"button rounded small delete btn-danger\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":70},"end":{"line":31,"column":85}}}))
    + "\" data-profile-field-id=\""
    + alias2((((helper = lookupProperty(helpers,"id") || alias3(depth0, "id", {"start":{"line":31,"column":112},"end":{"line":31,"column":114}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":31,"column":110},"end":{"line":31,"column":116}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n        </button>\n    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"profile_field"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":37,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});