var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"only-visible-for-spectators\">\n                <div class=\"realm-description\">\n                    <div class=\"rendered_markdown\">"
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"realm_rendered_description"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":30,"column":51},"end":{"line":30,"column":100}}}))
    + "</div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"right-sidebar\" id=\"right-sidebar\" role=\"navigation\">\n    <div class=\"right-sidebar-items\">\n        <div id=\"user-list\">\n            <div id=\"userlist-header\">\n                <h4 class='right-sidebar-title' data-tooltip-template-id=\"search-people-tooltip-template\"\n                  id='userlist-title'>\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"USERS",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":34}}}))
    + "\n                </h4>\n                <i id=\"user_filter_icon\" class=\"fa fa-search\"\n                  aria-hidden=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search people",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":49},"end":{"line":10,"column":71}}}))
    + "\"\n                  data-tooltip-template-id=\"search-people-tooltip-template\">\n                </i>\n            </div>\n            <div class=\"input-append notdisplayed\" id=\"user_search_section\">\n                <input class=\"user-list-filter home-page-input filter_text_input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search people",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":126},"end":{"line":15,"column":148}}}))
    + "\" />\n                <button type=\"button\" class=\"btn clear_search_button\" id=\"clear_search_people_button\">\n                    <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                </button>\n            </div>\n            <div id=\"buddy_list_wrapper\" class=\"scrolling_list\" data-simplebar>\n                <ul id=\"buddy-list-users-matching-view\" class=\"filters\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No matching users.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":99},"end":{"line":21,"column":126}}}))
    + "\"></ul>\n                <div id=\"buddy_list_wrapper_padding\"></div>\n            </div>\n        </div>\n        <div class=\"right-sidebar-shortcuts\">\n            <a class=\"invite-user-link\" role=\"button\"><i class=\"fa fa-user-plus\" aria-hidden=\"true\"></i>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invite more users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":104},"end":{"line":26,"column":130}}}))
    + "</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"realm_rendered_description"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":33,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});