var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":12,"column":23}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <span class=\""
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":11,"column":31},"end":{"line":11,"column":48}} ), depth0))
    + " user_circle popover_user_presence tippy-zulip-tooltip hidden-for-spectators\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "user_last_seen_time_status", {"start":{"line":11,"column":150},"end":{"line":11,"column":176}} ), depth0))
    + "\"></span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"user-card-popover-action-buttons\">\n                <a class=\"user-card-popover-manage-menu-btn\" role=\"button\" tabindex=\"0\" aria-haspopup=\"true\">\n                    <i class=\"popover_action_icon zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i>\n                </a>\n            </span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"user_email"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":33,"column":19}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "            <div>\n                <li class=\"user_popover_email\">\n                    <i class=\"fa fa-clone hide_copy_icon\" data-clipboard-text=\""
    + alias3(alias2(alias1(depth0, "user_email", {"start":{"line":29,"column":82},"end":{"line":29,"column":92}} ), depth0))
    + "\"></i>\n                    "
    + alias3(alias2(alias1(depth0, "user_email", {"start":{"line":30,"column":23},"end":{"line":30,"column":33}} ), depth0))
    + "\n                </li>\n            </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"user_popover_email half-opacity italic\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":35,"column":63},"end":{"line":35,"column":171}}})) != null ? stack1 : "")
    + "</li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This bot has been deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":77},"end":{"line":35,"column":116}}}));
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This user has been deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":124},"end":{"line":35,"column":164}}}));
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"bot_owner"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":50,"column":19}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"bot_owner\" data-tippy-content=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"bot_owner"), "full_name", {"start":{"line":41,"column":61},"end":{"line":41,"column":80}} ), depth0))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":85},"end":{"line":41,"column":103}}}))
    + ":\n                    <span class=\"bot-owner-name view_user_profile\" data-user-id='"
    + alias3(alias2(alias1(lookupProperty(depth0,"bot_owner"), "user_id", {"start":{"line":42,"column":84},"end":{"line":42,"column":101}} ), depth0))
    + "'>\n                        "
    + alias3(alias2(alias1(lookupProperty(depth0,"bot_owner"), "full_name", {"start":{"line":43,"column":26},"end":{"line":43,"column":45}} ), depth0))
    + "\n                    </span>\n                </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_system_bot"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":50,"column":12}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"System bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":20},"end":{"line":47,"column":39}}}))
    + "</li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":20},"end":{"line":49,"column":32}}}))
    + "</li>\n            ";
},"24":function(container,depth0,helpers,partials,data) {
    return "            <li>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_type", {"start":{"line":52,"column":19},"end":{"line":52,"column":28}} ), depth0))
    + "</li>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "            <hr />\n";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"hidden-for-spectators local_time\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"{user_time} local time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":57},"end":{"line":63,"column":88}}}))
    + "</li>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"user-card-status-text\">\n                <span id=\"status_message\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_emoji_info"),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":20},"end":{"line":77,"column":27}}})) != null ? stack1 : "")
    + "                    <span class=\"status_text\">\n                        "
    + container.escapeExpression(container.lambda(container.strict(depth0, "status_text", {"start":{"line":79,"column":26},"end":{"line":79,"column":37}} ), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":24},"end":{"line":82,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                </span>\n            </li>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":70,"column":24},"end":{"line":76,"column":31}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":71,"column":65},"end":{"line":71,"column":93}} ), depth0))
    + ":</div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"url"),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":72,"column":24},"end":{"line":76,"column":24}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <img src=\""
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "url", {"start":{"line":73,"column":40},"end":{"line":73,"column":61}} ), depth0))
    + "\" class=\"emoji status-emoji\" data-tippy-content=\":"
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":73,"column":115},"end":{"line":73,"column":143}} ), depth0))
    + ":\" />\n";
},"37":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"emoji status-emoji emoji-"
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "emoji_code", {"start":{"line":75,"column":67},"end":{"line":75,"column":95}} ), depth0))
    + "\" data-tippy-content=\":"
    + alias3(alias2(alias1(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":75,"column":122},"end":{"line":75,"column":150}} ), depth0))
    + ":\" ></div>\n                        ";
},"39":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span>(<a tabindex=\"0\" class=\"user-card-clear-status-button\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"clear",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":85},"end":{"line":81,"column":99}}}))
    + "</a>)</span>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n                <a tabindex=\"0\" class=\"update_status_text\">\n                    <i class=\"fa fa-comments\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_text"),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":92,"column":20},"end":{"line":96,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"invisible_mode"),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":99,"column":12},"end":{"line":111,"column":19}}})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":24},"end":{"line":93,"column":44}}}))
    + "\n";
},"44":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Set a status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":24},"end":{"line":95,"column":45}}}))
    + "\n";
},"46":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n                <a tabindex=\"0\" class=\"invisible_mode_turn_off\">\n                    <i class=\"fa fa-circle-o\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Turn off invisible mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":102,"column":70},"end":{"line":102,"column":102}}}))
    + "\n                </a>\n            </li>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n                <a tabindex=\"0\" class=\"invisible_mode_turn_on\">\n                    <i class=\"fa fa-circle-o\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Go invisible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":108,"column":70},"end":{"line":108,"column":91}}}))
    + "\n                </a>\n            </li>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <hr />\n            <li>\n                <a tabindex=\"0\" class=\"view_full_user_profile\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":119,"column":20},"end":{"line":123,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_send_private_message"),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":12},"end":{"line":132,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_me"),{"name":"unless","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":12},"end":{"line":149,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":12},"end":{"line":156,"column":19}}})) != null ? stack1 : "")
    + "            <hr />\n            <li>\n                <a href=\""
    + alias4(alias3(alias2(depth0, "pm_with_url", {"start":{"line":159,"column":28},"end":{"line":159,"column":39}} ), depth0))
    + "\" class=\"narrow_to_private_messages\">\n                    <i class=\"fa fa-envelope\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.program(72, data, 0),"data":data,"loc":{"start":{"line":161,"column":20},"end":{"line":165,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n            </li>\n            <li>\n                <a href=\""
    + alias4(alias3(alias2(depth0, "sent_by_url", {"start":{"line":169,"column":28},"end":{"line":169,"column":39}} ), depth0))
    + "\" class=\"narrow_to_messages_sent\">\n                    <i class=\"fa fa-paper-plane\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias1,"View messages sent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":170,"column":73},"end":{"line":170,"column":100}}}))
    + "\n                </a>\n            </li>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"fa fa-user\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View your profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":120,"column":70},"end":{"line":120,"column":96}}}))
    + "\n";
},"53":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"fa fa-user\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":70},"end":{"line":122,"column":91}}}))
    + "\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>\n                    <a tabindex=\"0\" class=\""
    + alias1(container.lambda(container.strict(depth0, "private_message_class", {"start":{"line":128,"column":46},"end":{"line":128,"column":67}} ), depth0))
    + "\">\n                        <i class=\"fa fa-comment\" aria-hidden=\"true\"></i> "
    + alias1(lookupProperty(helpers,"t").call(alias2,"Send direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":129,"column":73},"end":{"line":129,"column":101}}}))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_sender_popover"),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":102},"end":{"line":129,"column":170}}})) != null ? stack1 : "")
    + "\n                    </a>\n                </li>\n";
},"56":function(container,depth0,helpers,partials,data) {
    return "<span class=\"hotkey-hint\">(R)</span>";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"has_message_context"),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.program(66, data, 0),"data":data,"loc":{"start":{"line":135,"column":16},"end":{"line":147,"column":23}}})) != null ? stack1 : "")
    + "            </li>\n";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a tabindex=\"0\" class=\"mention_user\">\n                    <i class=\"fa fa-at\" aria-hidden=\"true\"></i>\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.program(62, data, 0),"data":data,"loc":{"start":{"line":138,"column":20},"end":{"line":138,"column":108}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_sender_popover"),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":20},"end":{"line":139,"column":88}}})) != null ? stack1 : "")
    + "\n                </a>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reply mentioning bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":138,"column":34},"end":{"line":138,"column":63}}}));
},"62":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reply mentioning user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":138,"column":71},"end":{"line":138,"column":101}}}));
},"64":function(container,depth0,helpers,partials,data) {
    return "<span class=\"hotkey-hint\">(@)</span>";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a tabindex=\"0\" class=\"copy_mention_syntax\" data-clipboard-text=\""
    + alias1(container.lambda(container.strict(depth0, "user_mention_syntax", {"start":{"line":142,"column":84},"end":{"line":142,"column":103}} ), depth0))
    + "\">\n                    <i class=\"fa fa-at\" aria-hidden=\"true\"></i>\n                    "
    + alias1(lookupProperty(helpers,"t").call(alias2,"Copy mention syntax",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":144,"column":20},"end":{"line":144,"column":48}}}))
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_sender_popover"),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":20},"end":{"line":145,"column":88}}})) != null ? stack1 : "")
    + "\n                </a>\n";
},"68":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n                <a href=\"/#settings/profile\">\n                    <i class=\"fa fa-edit\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit your profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":153,"column":66},"end":{"line":153,"column":92}}}))
    + "\n                </a>\n            </li>\n";
},"70":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View messages with yourself",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":162,"column":24},"end":{"line":162,"column":60}}}))
    + "\n";
},"72":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View direct messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":164,"column":24},"end":{"line":164,"column":53}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"user-card-popover-title no-auto-hide-right-sidebar-overlay\"></div>\n<div class=\"user-card-popover-content no-auto-hide-right-sidebar-overlay\">\n    <ul class=\"nav nav-list user-card-popover-actions\" id=\"user_card_popover\" data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":4,"column":94},"end":{"line":4,"column":101}} ), depth0))
    + "\">\n        <li class=\"popover_user_name_row\">\n            <b class=\"user_full_name\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "user_full_name", {"start":{"line":6,"column":60},"end":{"line":6,"column":74}} ), depth0))
    + "\">"
    + ((stack1 = container.invokePartial(require("../../user_full_name.hbs"),depth0,{"name":"../../user_full_name","hash":{"name":lookupProperty(depth0,"user_full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</b>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"show_manage_menu"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "        </li>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("../../user_custom_profile_fields.hbs"),depth0,{"name":"../../user_custom_profile_fields","hash":{"for_user_card_popover":true,"profile_fields":lookupProperty(depth0,"display_profile_fields")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <li class=\"only-visible-for-spectators\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Joined {date_joined}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":48},"end":{"line":57,"column":77}}}))
    + "</li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"or").call(alias4,lookupProperty(helpers,"and").call(alias4,lookupProperty(depth0,"user_time"),lookupProperty(depth0,"is_active"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":59,"column":18},"end":{"line":59,"column":43}}}),lookupProperty(depth0,"is_me"),lookupProperty(depth0,"status_content_available"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":59,"column":14},"end":{"line":59,"column":75}}}),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":61,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"and").call(alias4,lookupProperty(depth0,"user_time"),lookupProperty(depth0,"is_active"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":62,"column":14},"end":{"line":62,"column":39}}}),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":64,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"status_content_available"),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":8},"end":{"line":86,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_me"),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":8},"end":{"line":112,"column":15}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"spectator_view"),{"name":"unless","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":8},"end":{"line":173,"column":19}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"usePartial":true,"useData":true});