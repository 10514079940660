var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " guest-avatar";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"status-circle "
    + alias4((((helper = lookupProperty(helpers,"user_circle_class") || alias1(depth0, "user_circle_class", {"start":{"line":8,"column":45},"end":{"line":8,"column":62}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_circle_class","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":64}}}) : helper)))
    + " user_circle hidden-for-spectators\" data-tippy-placement=\"bottom\" data-tippy-content=\""
    + alias4((((helper = lookupProperty(helpers,"user_last_seen_time_status") || alias1(depth0, "user_last_seen_time_status", {"start":{"line":8,"column":152},"end":{"line":8,"column":178}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_last_seen_time_status","hash":{},"data":data,"loc":{"start":{"line":8,"column":150},"end":{"line":8,"column":180}}}) : helper)))
    + "\"></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"navbar-dropdown-menu-outer-list-item\">\n                    <ul class=\"navbar-dropdown-menu-inner-list\">\n                        <li class=\"text-item hidden-for-spectators navbar-dropdown-menu-inner-list-item\">\n                            <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-clock\"></i>\n                            "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":24,"column":64}}})) != null ? stack1 : "")
    + "\n                        </li>\n                    </ul>\n                </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "{user_time} local time";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"text-item navbar-dropdown-menu-inner-list-item\">\n                            <span class=\"personal-menu-status-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_emoji_info"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":32},"end":{"line":42,"column":39}}})) != null ? stack1 : "")
    + "                                <span class=\"status_text personal-menu-status-text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_placeholder_for_status_text"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":44,"column":36},"end":{"line":48,"column":43}}})) != null ? stack1 : "")
    + "                                </span>\n                            </span>\n                            <a tabindex=\"0\" class=\"personal-menu-clear-status navbar-dropdown-menu-link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":117},"end":{"line":51,"column":137}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear your status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":159},"end":{"line":51,"column":185}}}))
    + "\">\n                                <i class=\"personal-menu-clear-status-icon navbar-dropdown-icon zulip-icon zulip-icon-x-circle\"></i>\n                            </a>\n                        </li>\n                        <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"update_status_text navbar-dropdown-menu-link\">\n                                <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-smile-smaller\"></i>\n                                "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":32},"end":{"line":58,"column":57}}})) != null ? stack1 : "")
    + "\n                            </a>\n                        </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":35,"column":36},"end":{"line":41,"column":43}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <span class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":36,"column":74},"end":{"line":36,"column":102}} ), depth0))
    + ":</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"url"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":37,"column":36},"end":{"line":41,"column":36}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <img src=\""
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "url", {"start":{"line":38,"column":48},"end":{"line":38,"column":69}} ), depth0))
    + "\" class=\"emoji status_emoji\" />\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <span class=\"emoji status_emoji emoji-"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "emoji_code", {"start":{"line":40,"column":76},"end":{"line":40,"column":104}} ), depth0))
    + "\"></span>\n                                    ";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <i class=\"personal-menu-no-status-text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"No status text",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":80},"end":{"line":45,"column":102}}}))
    + "</i>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        "
    + container.escapeExpression((((helper = lookupProperty(helpers,"status_text") || container.strict(depth0, "status_text", {"start":{"line":47,"column":42},"end":{"line":47,"column":53}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status_text","hash":{},"data":data,"loc":{"start":{"line":47,"column":40},"end":{"line":47,"column":55}}}) : helper)))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "Edit status";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"link-item hidden-for-spectators navbar-dropdown-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"update_status_text navbar-dropdown-menu-link\">\n                                <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-smile-smaller\"></i>\n                                "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":32},"end":{"line":65,"column":56}}})) != null ? stack1 : "")
    + "\n                            </a>\n                        </li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "Set status";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"link-item hidden-for-spectators navbar-dropdown-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"invisible_mode_turn_off navbar-dropdown-menu-link\">\n                                <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-play-circle\"></i>\n                                "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":32},"end":{"line":74,"column":69}}})) != null ? stack1 : "")
    + "\n                            </a>\n                        </li>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "Turn off invisible mode";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"link-item hidden-for-spectators navbar-dropdown-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"invisible_mode_turn_on navbar-dropdown-menu-link\">\n                                <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-stop-circle\"></i>\n                                "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":32},"end":{"line":81,"column":58}}})) != null ? stack1 : "")
    + "\n                            </a>\n                        </li>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "Go invisible";
},"32":function(container,depth0,helpers,partials,data) {
    return "View your profile";
},"34":function(container,depth0,helpers,partials,data) {
    return "View messages with yourself";
},"36":function(container,depth0,helpers,partials,data) {
    return "View messages sent";
},"38":function(container,depth0,helpers,partials,data) {
    return "Settings";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"navbar-dropdown-menu\" id=\"personal-menu-dropdown\" data-simplebar>\n    <nav class=\"personal-menu-nav\">\n        <header class=\"personal-menu-header\">\n            <div class=\"avatar\">\n                <img class=\"avatar-image"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_guest"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":81}}})) != null ? stack1 : "")
    + "\" src=\""
    + alias4((((helper = lookupProperty(helpers,"user_avatar") || alias2(depth0, "user_avatar", {"start":{"line":5,"column":90},"end":{"line":5,"column":101}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_avatar","hash":{},"data":data,"loc":{"start":{"line":5,"column":88},"end":{"line":5,"column":103}}}) : helper)))
    + "\"/>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"text-area\">\n                <p class=\"full-name\">"
    + alias4((((helper = lookupProperty(helpers,"user_full_name") || alias2(depth0, "user_full_name", {"start":{"line":13,"column":39},"end":{"line":13,"column":53}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_full_name","hash":{},"data":data,"loc":{"start":{"line":13,"column":37},"end":{"line":13,"column":55}}}) : helper)))
    + "</p>\n                <p class=\"user-type\">"
    + alias4((((helper = lookupProperty(helpers,"user_type") || alias2(depth0, "user_type", {"start":{"line":14,"column":39},"end":{"line":14,"column":48}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_type","hash":{},"data":data,"loc":{"start":{"line":14,"column":37},"end":{"line":14,"column":50}}}) : helper)))
    + "</p>\n            </div>\n        </header>\n        <section class=\"dropdown-menu-list-section personal-menu-actions\" data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias2(depth0, "user_id", {"start":{"line":17,"column":90},"end":{"line":17,"column":97}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":17,"column":88},"end":{"line":17,"column":99}}}) : helper)))
    + "\">\n            <ul class=\"navbar-dropdown-menu-outer-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_time"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "                <li class=\"navbar-dropdown-menu-outer-list-item\">\n                    <ul class=\"navbar-dropdown-menu-inner-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_content_available"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":68,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"invisible_mode"),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":70,"column":24},"end":{"line":84,"column":31}}})) != null ? stack1 : "")
    + "                    </ul>\n                </li>\n                <li class=\"navbar-dropdown-menu-outer-list-item\">\n                    <ul class=\"navbar-dropdown-menu-inner-list\">\n                        <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"view_full_user_profile navbar-dropdown-menu-link\">\n                                <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-account\"></i>\n                                "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":32},"end":{"line":92,"column":63}}})) != null ? stack1 : "")
    + "\n                            </a>\n                        </li>\n                        <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"narrow-self-direct-message navbar-dropdown-menu-link\">\n                                <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-users\"></i>\n                                "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":32},"end":{"line":98,"column":73}}})) != null ? stack1 : "")
    + "\n                            </a>\n                        </li>\n                        <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"narrow-messages-sent navbar-dropdown-menu-link\">\n                                <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-message-square\"></i>\n                                "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":32},"end":{"line":104,"column":64}}})) != null ? stack1 : "")
    + "\n                            </a>\n                        </li>\n                    </ul>\n                </li>\n                <li class=\"navbar-dropdown-menu-outer-list-item\">\n                    <ul class=\"navbar-dropdown-menu-inner-list\">\n                        <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                            <a href=\"#settings/profile\" class=\"open-profile-settings navbar-dropdown-menu-link\">\n                                <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-tool\"></i>\n                                "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":32},"end":{"line":114,"column":54}}})) != null ? stack1 : "")
    + "\n                            </a>\n                        </li>\n                    </ul>\n                </li>\n                <li class=\"navbar-dropdown-menu-outer-list-item\">\n                    <ul class=\"navbar-dropdown-menu-inner-list\">\n                        <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                            <a class=\"logout_button hidden-for-spectators navbar-dropdown-menu-link\" tabindex=\"0\">\n                                <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-log-out\" aria-hidden=\"true\"></i>\n                                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Log out",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":32},"end":{"line":124,"column":48}}}))
    + "\n                            </a>\n                        </li>\n                    </ul>\n                </li>\n            </ul>\n        </section>\n    </nav>\n</div>\n";
},"useData":true});