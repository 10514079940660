var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"change_password_container\">\n    <div class=\"field password-div\">\n        <label for=\"old_password\" class=\"title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Old password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":48},"end":{"line":3,"column":69}}}))
    + "</label>\n        <input type=\"password\" autocomplete=\"off\" name=\"old_password\" id=\"old_password\" class=\"w-200 inline-block modal_password_input\" value=\"\" />\n        <i class=\"fa fa-eye-slash password_visibility_toggle tippy-zulip-tooltip\" role=\"button\"></i>\n        <div class=\"settings-forgot-password\">\n            <a href=\"/accounts/password/reset/\" class=\"sea-green\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Forgot it?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":108},"end":{"line":7,"column":127}}}))
    + "</a>\n        </div>\n    </div>\n    <div class=\"field password-div\">\n        <label for=\"new_password\" class=\"title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":48},"end":{"line":11,"column":69}}}))
    + "</label>\n        <input type=\"password\" autocomplete=\"new-password\" name=\"new_password\" id=\"new_password\" class=\"w-200 inline-block modal_password_input\" value=\"\"\n          data-min-length=\""
    + alias2((((helper = lookupProperty(helpers,"password_min_length") || alias3(depth0, "password_min_length", {"start":{"line":13,"column":29},"end":{"line":13,"column":48}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"password_min_length","hash":{},"data":data,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":50}}}) : helper)))
    + "\" data-min-guesses=\""
    + alias2((((helper = lookupProperty(helpers,"password_min_guesses") || alias3(depth0, "password_min_guesses", {"start":{"line":13,"column":72},"end":{"line":13,"column":92}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"password_min_guesses","hash":{},"data":data,"loc":{"start":{"line":13,"column":70},"end":{"line":13,"column":94}}}) : helper)))
    + "\" />\n        <i class=\"fa fa-eye-slash password_visibility_toggle tippy-zulip-tooltip\" role=\"button\"></i>\n        <div class=\"progress inline-block\" id=\"pw_strength\">\n            <div class=\"bar bar-danger fade\" style=\"width: 10%;\"></div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});