var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "If you don't know your password, you can <z-link>reset it</z-link>.\n                                \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/accounts/password/reset/\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"api_key_modal\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"api_key_modal_label\">\n            <header class=\"modal__header\">\n                <h1 class=\"modal__title\" id=\"api_key_modal_label\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":41}}}))
    + "\n                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </header>\n            <main class=\"modal__content\">\n                <div id=\"password_confirmation\">\n                    <span class=\"alert-notification\" id=\"api_key_status\"></span>\n                    <div id=\"api_key_form\">\n                        <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Please re-enter your password to confirm your identity.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":27},"end":{"line":14,"column":91}}}))
    + "</p>\n                        <div class=\"password-div\">\n                            <label for=\"password\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":50},"end":{"line":16,"column":72}}}))
    + "</label>\n                            <input type=\"password\" autocomplete=\"off\" name=\"password\" id=\"get_api_key_password\" class=\" modal_password_input\" value=\"\" />\n                            <i class=\"fa fa-eye-slash password_visibility_toggle tippy-zulip-tooltip\" role=\"button\"></i>\n                        </div>\n                        <p class=\"small\">\n                            "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":28},"end":{"line":23,"column":35}}})) != null ? stack1 : "")
    + "                        </p>\n                    </div>\n                </div>\n                <div id=\"show_api_key\">\n                    <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your API key:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":23},"end":{"line":28,"column":45}}}))
    + "</p>\n                    <p><b><span id=\"api_key_value\"></span></b></p>\n                    <div id=\"user_api_key_error\" class=\"text-error\"></div>\n                </div>\n            </main>\n            <footer class=\"modal__footer\">\n                <button type=\"submit\" name=\"view_api_key\" id=\"get_api_key_button\" class=\"modal__btn dialog_submit_button\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Get API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":35,"column":40}}}))
    + "\n                </button>\n                <div id=\"api_key_buttons\">\n                    <button class=\"modal__btn dialog_submit_button\" id=\"regenerate_api_key\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generate new API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":104},"end":{"line":38,"column":133}}}))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generate new API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":135},"end":{"line":38,"column":164}}}))
    + "</button>\n                    <a class=\"modal__btn dialog_submit_button\" id=\"download_zuliprc\" download=\"zuliprc\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Download .zuliprc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":117},"end":{"line":39,"column":143}}}))
    + "</a>\n                </div>\n            </footer>\n        </div>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":22,"column":166}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});