var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <span class=\"my_user_status\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(you)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":61},"end":{"line":4,"column":74}}}))
    + "</span>";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td class=\"subscriber-email\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"email") || container.strict(depth0, "email", {"start":{"line":7,"column":39},"end":{"line":7,"column":44}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":46}}}) : helper)))
    + "</td>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td class=\"hidden-subscriber-email\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(hidden)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":44},"end":{"line":9,"column":60}}}))
    + "</td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    <td>\n        <a data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias1(depth0, "user_id", {"start":{"line":3,"column":27},"end":{"line":3,"column":34}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":36}}}) : helper)))
    + "\" class=\"view_user_profile\" tabindex=\"0\">"
    + alias4((((helper = lookupProperty(helpers,"full_name") || alias1(depth0, "full_name", {"start":{"line":3,"column":79},"end":{"line":3,"column":88}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"full_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":77},"end":{"line":3,"column":90}}}) : helper)))
    + "</a>\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_current_user"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":88}}})) != null ? stack1 : "")
    + "\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"email"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "    <td>\n        <button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"disabled"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":59}}})) != null ? stack1 : "")
    + " data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias1(depth0, "user_id", {"start":{"line":12,"column":76},"end":{"line":12,"column":83}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":12,"column":74},"end":{"line":12,"column":85}}}) : helper)))
    + "\" class=\"remove_potential_subscriber button small rounded white\">"
    + alias4(lookupProperty(helpers,"t").call(alias2,"Remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":150},"end":{"line":12,"column":165}}}))
    + "</button>\n    </td>\n</tr>\n";
},"useData":true});