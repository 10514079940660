var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " sender-status-controls";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"edit_content message_control_button\" data-tooltip-template-id=\"view-source-tooltip-template\"></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"reaction_button message_control_button\" data-tooltip-template-id=\"add-emoji-tooltip-template\">\n        <div class=\"emoji-message-control-button-container\">\n            <i class=\"zulip-icon zulip-icon-smile\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":63},"end":{"line":9,"column":90}}}))
    + " (:)\" role=\"button\" aria-haspopup=\"true\" tabindex=\"0\"></i>\n        </div>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"actions_hover message_control_button\" data-tooltip-template-id=\"message-actions-tooltip-template\" >\n        <i class=\"message-actions-menu-button zulip-icon zulip-icon-more-vertical-spread\" role=\"button\" aria-haspopup=\"true\" tabindex=\"0\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Message actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":150},"end":{"line":16,"column":174}}}))
    + "\"></i>\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "hide";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"star_container message_control_button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":31,"column":54},"end":{"line":31,"column":98}}})) != null ? stack1 : "")
    + "\" data-tooltip-template-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":31,"column":126},"end":{"line":31,"column":170}}})) != null ? stack1 : "")
    + "-message-tooltip-template\">\n        <i role=\"button\" tabindex=\"0\" class=\"star zulip-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":32,"column":61},"end":{"line":32,"column":132}}})) != null ? stack1 : "")
    + "\"></i>\n    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "";
},"14":function(container,depth0,helpers,partials,data) {
    return "empty-star";
},"16":function(container,depth0,helpers,partials,data) {
    return "unstar";
},"18":function(container,depth0,helpers,partials,data) {
    return "star";
},"20":function(container,depth0,helpers,partials,data) {
    return "zulip-icon-star-filled";
},"22":function(container,depth0,helpers,partials,data) {
    return "zulip-icon-star";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"message_controls";
  stack1 = (((helper = lookupProperty(helpers,"status_message") || container.strict(depth0, "status_message", {"start":{"line":1,"column":31},"end":{"line":1,"column":45}} ))),(options={"name":"status_message","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":89}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"status_message")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " no-select\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"sent_by_me"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"sent_by_me"),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"locally_echoed"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "\n    <div class=\"message_failed "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"failed_request"),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":31},"end":{"line":20,"column":76}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"message_control_button failed_message_action\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retry",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":86},"end":{"line":21,"column":100}}}))
    + "\">\n            <i class=\"fa fa-refresh refresh-failed-message\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retry",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":72},"end":{"line":22,"column":86}}}))
    + "\" role=\"button\" tabindex=\"0\"></i>\n        </div>\n\n        <div class=\"message_control_button failed_message_action\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":86},"end":{"line":25,"column":101}}}))
    + "\">\n            <i class=\"fa fa-times-circle remove-failed-message\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":76},"end":{"line":26,"column":91}}}))
    + "\" role=\"button\" tabindex=\"0\"></i>\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"locally_echoed"),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});