var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a href=\"/help/contact-support\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-life-buoy\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Contact support",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":112},"end":{"line":34,"column":136}}}))
    + "\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"navbar-dropdown-menu\" id=\"help-menu-dropdown\" aria-labelledby=\"help-menu\" data-simplebar>\n    <ul class=\"navbar-dropdown-menu-outer-list\">\n        <li class=\"navbar-dropdown-menu-outer-list-item\">\n            <ul class=\"navbar-dropdown-menu-inner-list\">\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a href=\"/help/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-help\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Help center",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":107},"end":{"line":7,"column":127}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a tabindex=\"0\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\" data-overlay-trigger=\"keyboard-shortcuts\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-keyboard\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Keyboard shortcuts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":111},"end":{"line":12,"column":138}}}))
    + " <span class=\"navbar-dropdown-hotkey-hint\">?</span>\n                    </a>\n                </li>\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item hidden-for-spectators\">\n                    <a tabindex=\"0\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\" data-overlay-trigger=\"message-formatting\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message formatting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":107},"end":{"line":17,"column":134}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a tabindex=\"0\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\" data-overlay-trigger=\"search-operators\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-manage-search\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search filters",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":116},"end":{"line":22,"column":139}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item\" id=\"gear_menu_about_zulip\">\n                    <a href=\"#about-zulip\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-info\"></i>\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"About Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":28,"column":44}}}))
    + "\n                    </a>\n                </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"corporate_enabled"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":37,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});