var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"method_row\" data-method=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "method", {"start":{"line":1,"column":39},"end":{"line":1,"column":45}} ), depth0))
    + "\">\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"is_disabled":lookupProperty(depth0,"disable_configure_auth_method"),"label":lookupProperty(depth0,"method"),"is_checked":lookupProperty(depth0,"enabled"),"prefix":lookupProperty(depth0,"prefix"),"setting_name":"realm_authentication_methods"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});