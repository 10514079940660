var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"respond_button\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":5,"column":53},"end":{"line":5,"column":63}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":65}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-reply\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Quote and reply",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":59},"end":{"line":6,"column":83}}}))
    + "\n            <span class=\"hotkey-hint\">(>)</span>\n        </a>\n    </li>\n    <hr />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_edit_message\" data-message-id=\""
    + alias4((((helper = lookupProperty(helpers,"message_id") || alias1(depth0, "message_id", {"start":{"line":15,"column":59},"end":{"line":15,"column":69}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":15,"column":57},"end":{"line":15,"column":71}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i> "
    + alias4((((helper = lookupProperty(helpers,"editability_menu_item") || alias1(depth0, "editability_menu_item", {"start":{"line":16,"column":76},"end":{"line":16,"column":97}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"editability_menu_item","hash":{},"data":data,"loc":{"start":{"line":16,"column":74},"end":{"line":16,"column":99}}}) : helper)))
    + "\n            <span class=\"hotkey-hint\">(e)</span>\n        </a>\n    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_move_message\" data-message-id=\""
    + alias4((((helper = lookupProperty(helpers,"message_id") || alias1(depth0, "message_id", {"start":{"line":24,"column":59},"end":{"line":24,"column":69}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":24,"column":57},"end":{"line":24,"column":71}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"zulip-icon zulip-icon-move-alt\" aria-hidden=\"true\"></i> "
    + alias4((((helper = lookupProperty(helpers,"move_message_menu_item") || alias1(depth0, "move_message_menu_item", {"start":{"line":25,"column":80},"end":{"line":25,"column":102}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"move_message_menu_item","hash":{},"data":data,"loc":{"start":{"line":25,"column":78},"end":{"line":25,"column":104}}}) : helper)))
    + "\n            <span class=\"hotkey-hint\">(m)</span>\n        </a>\n    </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"delete_message\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":33,"column":53},"end":{"line":33,"column":63}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":33,"column":51},"end":{"line":33,"column":65}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":35,"column":35}}}))
    + "\n        </a>\n    </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <hr />\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"reaction_button\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":46,"column":54},"end":{"line":46,"column":64}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":46,"column":52},"end":{"line":46,"column":66}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"zulip-icon zulip-icon-smile\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":48,"column":39}}}))
    + " <span class=\"hotkey-hint\">(:)</span>\n        </a>\n    </li>\n    <hr />\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n            <a class=\"mark_as_unread\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":56,"column":57},"end":{"line":56,"column":67}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":56,"column":55},"end":{"line":56,"column":69}}}) : helper)))
    + "\" tabindex=\"0\">\n                <span class=\"unread_count\">1</span>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as unread from here",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":58,"column":49}}}))
    + "\n                <span class=\"hotkey-hint\">(U)</span>\n            </a>\n        </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"rehide_muted_user_message\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":66,"column":64},"end":{"line":66,"column":74}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":66,"column":62},"end":{"line":66,"column":76}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-eye-slash\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Hide muted message again",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":12},"end":{"line":68,"column":45}}}))
    + "\n        </a>\n    </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_toggle_collapse\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":75,"column":62},"end":{"line":75,"column":72}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":75,"column":60},"end":{"line":75,"column":74}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-minus\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":12},"end":{"line":77,"column":37}}}))
    + " <span class=\"hotkey-hint\">(–)</span>\n        </a>\n    </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_toggle_collapse\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":84,"column":62},"end":{"line":84,"column":72}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":84,"column":60},"end":{"line":84,"column":74}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-plus\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expand message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":12},"end":{"line":86,"column":35}}}))
    + " <span class=\"hotkey-hint\">(–)</span>\n        </a>\n    </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_view_source\" data-message-id=\""
    + alias4((((helper = lookupProperty(helpers,"message_id") || alias1(depth0, "message_id", {"start":{"line":97,"column":58},"end":{"line":97,"column":68}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":97,"column":56},"end":{"line":97,"column":70}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-file-code-o\" aria-hidden=\"true\"></i> "
    + alias4((((helper = lookupProperty(helpers,"view_source_menu_item") || alias1(depth0, "view_source_menu_item", {"start":{"line":98,"column":67},"end":{"line":98,"column":88}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"view_source_menu_item","hash":{},"data":data,"loc":{"start":{"line":98,"column":65},"end":{"line":98,"column":90}}}) : helper)))
    + "\n            <span class=\"hotkey-hint\">(e)</span>\n        </a>\n    </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"view_read_receipts\" data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":106,"column":57},"end":{"line":106,"column":67}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":106,"column":55},"end":{"line":106,"column":69}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"zulip-icon zulip-icon-readreceipts\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"View read receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":70},"end":{"line":107,"column":97}}}))
    + "\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View read receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":104},"end":{"line":107,"column":131}}}))
    + "\n        </a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list actions_popover\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_quote_and_reply"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"editability_menu_item"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"move_message_menu_item"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":29,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_delete_option"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"editability_menu_item"),lookupProperty(depth0,"move_message_menu_item"),lookupProperty(depth0,"should_display_delete_option"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":40,"column":88}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":42,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_add_reaction_option"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":4},"end":{"line":52,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_mark_as_unread"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":4},"end":{"line":62,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_hide_option"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":4},"end":{"line":71,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_collapse"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":4},"end":{"line":80,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_uncollapse"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":4},"end":{"line":89,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"should_display_mark_as_unread"),lookupProperty(depth0,"should_display_reminder_option"),lookupProperty(depth0,"should_display_hide_option"),lookupProperty(depth0,"should_display_collapse"),lookupProperty(depth0,"should_display_uncollapse"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":91,"column":10},"end":{"line":91,"column":152}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":4},"end":{"line":93,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"view_source_menu_item"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":4},"end":{"line":102,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_read_receipts_option"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":4},"end":{"line":110,"column":11}}})) != null ? stack1 : "")
    + "\n    <li>\n        <a class=\"copy_link navigate-link-on-enter\" data-message-id=\""
    + alias4((((helper = lookupProperty(helpers,"message_id") || alias2(depth0, "message_id", {"start":{"line":113,"column":71},"end":{"line":113,"column":81}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":113,"column":69},"end":{"line":113,"column":83}}}) : helper)))
    + "\" data-clipboard-text=\""
    + alias4((((helper = lookupProperty(helpers,"conversation_time_url") || alias2(depth0, "conversation_time_url", {"start":{"line":113,"column":109},"end":{"line":113,"column":130}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"conversation_time_url","hash":{},"data":data,"loc":{"start":{"line":113,"column":106},"end":{"line":113,"column":133}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-link\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Copy link to message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":58},"end":{"line":114,"column":87}}}))
    + "\n        </a>\n    </li>\n</ul>\n";
},"useData":true});