var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./stream_privacy_icon.hbs"),depth0,{"name":"stream_privacy_icon","hash":{"color":lookupProperty(depth0,"title_icon_color"),"is_web_public":lookupProperty(lookupProperty(depth0,"sub"),"is_web_public"),"invite_only":lookupProperty(lookupProperty(depth0,"sub"),"invite_only")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<span class=\"stream-name-title\">"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"sub"), "name", {"start":{"line":5,"column":34},"end":{"line":5,"column":42}} ), depth0))
    + "</span>\n";
},"usePartial":true,"useData":true});