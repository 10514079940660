var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"input-group\">\n                        <label class=\"inline-block title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":58},"end":{"line":11,"column":72}}}))
    + "</label>\n                        <div id=\"change_email_button_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_can_change_email"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":84},"end":{"line":12,"column":152}}})) != null ? stack1 : "")
    + "\">\n                            <button id=\"change_email_button\" type=\"button\" class=\"button btn-link small rounded inline-block\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_can_change_email"),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":126},"end":{"line":13,"column":189}}})) != null ? stack1 : "")
    + ">\n                                "
    + alias2(container.lambda(container.strict(lookupProperty(depth0,"page_params"), "delivery_email", {"start":{"line":14,"column":34},"end":{"line":14,"column":60}} ), depth0))
    + "\n                                <i class=\"fa fa-pencil\"></i>\n                            </button>\n                        </div>\n                    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled_setting_tooltip";
},"4":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"input-group\">\n                        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":28,"column":35}}})) != null ? stack1 : "")
    + "                        </p>\n                        <button id=\"demo_organization_add_email_button\" type=\"button\" class=\"button rounded sea-green\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Add email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":28},"end":{"line":31,"column":45}}}))
    + "\n                        </button>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Add your email to <z-link-invite-users-help>invite other users</z-link-invite-users-help>\n                                or <z-link-convert-demo-organization-help>convert to a permanent Zulip organization</z-link-convert-demo-organization-help>.\n                                \n                                \n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/invite-new-users\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/demo-organizations#convert-a-demo-organization-to-a-permanent-organization\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p for=\"two_factor_auth\" class=\"inline-block title\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Two factor authentication",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":39,"column":54}}}))
    + ": "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"page_params"),"two_fa_enabled_user"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":39,"column":56},"end":{"line":39,"column":144}}})) != null ? stack1 : "")
    + "\n                    <a target=\"_blank\" rel=\"noopener noreferrer\" id=\"two_factor_auth\" href=\"/account/two_factor/\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Set up two factor authentication",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":121},"end":{"line":40,"column":162}}}))
    + "\">["
    + alias2(lookupProperty(helpers,"t").call(alias1,"Setup",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":165},"end":{"line":40,"column":179}}}))
    + "]</a>\n                </p>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Enabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":96},"end":{"line":39,"column":112}}}));
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Disabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":120},"end":{"line":39,"column":137}}}));
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div>\n                        <label class=\"inline-block title\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":58},"end":{"line":47,"column":75}}}))
    + "</label>\n                        <div class=\"input-group inline-block\" id=\"pw_change_link\">\n                            <button id=\"change_password\" type=\"button\" class=\"change_password_button btn-link small button rounded inline-block\" data-dismiss=\"modal\">********<i class=\"fa fa-pencil\"></i></button>\n                        </div>\n                    </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"submit\" class=\"button rounded btn-danger deactivate_realm_button\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Deactivate organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":28},"end":{"line":64,"column":60}}}))
    + "\n                        </button>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                    An API key can be used to programmatically access a Zulip account.\n                    Anyone with access to your API key has the ability to read your messages, send\n                    messages on your behalf, and otherwise impersonate you on Zulip, so you should\n                    guard your API key as carefully as you guard your password. <br />\n                    We recommend creating bots and using the bots' accounts and API keys to access\n                    the Zulip API, unless the task requires access to your account.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"account-settings\" class=\"settings-section show\" data-name=\"account-and-privacy\">\n    <div class=\"alert\" id=\"dev-account-settings-status\"></div>\n    <div class=\"account-settings-form\">\n        <div class=\"inline-block\">\n            <div id=\"user_details_section\">\n                <h3 class=\"inline-block account-settings-heading\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":66},"end":{"line":6,"column":82}}}))
    + "</h3>\n                <div class=\"alert-notification account-alert-notification\" id=\"account-settings-status\"></div>\n                <form class=\"grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":34,"column":27}}})) != null ? stack1 : "")
    + "                </form>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"page_params"),"two_fa_enabled"),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":42,"column":23}}})) != null ? stack1 : "")
    + "\n                <form class=\"password-change-form grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_can_change_password"),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":52,"column":27}}})) != null ? stack1 : "")
    + "                </form>\n\n                <div class=\"input-group\">\n                    <div id=\"deactivate_account_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_only_organization_owner"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":79},"end":{"line":56,"column":149}}})) != null ? stack1 : "")
    + "\">\n                        <button type=\"submit\" class=\"button rounded btn-danger\" id=\"user_deactivate_account_button\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_only_organization_owner"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":26},"end":{"line":58,"column":91}}})) != null ? stack1 : "")
    + ">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deactivate account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":28},"end":{"line":59,"column":55}}}))
    + "\n                        </button>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"owner_is_only_user_in_organization"),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":66,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n\n        <div id=\"privacy_settings_box\">\n            <h3 class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Privacy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":37},"end":{"line":72,"column":53}}}))
    + "</h3>\n            <div class=\"alert-notification privacy-setting-status\"></div>\n            <div class=\"input-group\">\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"settings_label"),"send_private_typing_notifications"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_private_typing_notifications"),"setting_name":"send_private_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"settings_label"),"send_stream_typing_notifications"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_stream_typing_notifications"),"setting_name":"send_stream_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/read-receipts","hide_tooltip":lookupProperty(lookupProperty(depth0,"page_params"),"realm_enable_read_receipts"),"tooltip_text":lookupProperty(depth0,"send_read_receipts_tooltip"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"send_read_receipts"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_read_receipts"),"setting_name":"send_read_receipts"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":"user_","help_link":"/help/status-and-availability","label_parens_text":lookupProperty(lookupProperty(depth0,"settings_label"),"presence_enabled_parens_text"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"presence_enabled"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"presence_enabled"),"setting_name":"presence_enabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"input-group\">\n                <label for=\"email_address_visibility\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can access your email address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":102,"column":77},"end":{"line":102,"column":119}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/configure-email-visibility"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </label>\n                <div id=\"user_email_address_dropdown_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":84},"end":{"line":105,"column":149}}})) != null ? stack1 : "")
    + "\">\n                    <select name=\"email_address_visibility\" class=\"email_address_visibility prop-element settings_select bootstrap-focus-style\" data-setting-widget-type=\"number\"\n                      id=\"user_email_address_visibility\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":57},"end":{"line":107,"column":117}}})) != null ? stack1 : "")
    + ">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"email_address_visibility_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n            </div>\n        </div>\n\n        <hr class=\"settings_separator\" />\n\n        <div id=\"api_key_button_box\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":117,"column":16},"end":{"line":117,"column":32}}}))
    + "</h3>\n\n            <div class=\"input-group\">\n                <p class=\"api-key-note\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":20},"end":{"line":128,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n                <div id=\"api_key_button_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":71},"end":{"line":130,"column":136}}})) != null ? stack1 : "")
    + "\">\n                    <button class=\"button rounded\" id=\"api_key_button\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":71},"end":{"line":131,"column":131}}})) != null ? stack1 : "")
    + ">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show/change your API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":132,"column":24},"end":{"line":132,"column":57}}}))
    + "\n                    </button>\n                </div>\n            </div>\n        </div>\n        <!-- Render /settings/api_key_modal.hbs after #api_key_button is clicked\n        to avoid password being inserted by password manager too aggressively. -->\n    </div>\n</div>\n";
},"7_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-invite-users-help"],"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":181}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-convert-demo-organization-help"],"data":data,"loc":{"start":{"line":27,"column":32},"end":{"line":27,"column":252}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});