var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_digest_emails_enabled"),"is_checked":lookupProperty(depth0,"realm_digest_emails_enabled"),"prefix":"id_","setting_name":"realm_digest_emails_enabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                            <option value='"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":115,"column":45},"end":{"line":115,"column":52}} ), depth0))
    + "'>"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":115,"column":58},"end":{"line":115,"column":67}} ), depth0))
    + "</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <option value=\"server_default\">\n                                        "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":40},"end":{"line":128,"column":89}}})) != null ? stack1 : "")
    + "\n                                    </option>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "{server_jitsi_server_url} (default)";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <option value=\"server_default\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Disabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":131,"column":67},"end":{"line":131,"column":84}}}))
    + "</option>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/allow-image-link-previews","label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_inline_image_preview"),"is_checked":lookupProperty(depth0,"realm_inline_image_preview"),"prefix":"id_","setting_name":"realm_inline_image_preview"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/allow-image-link-previews","label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_inline_url_embed_preview"),"is_checked":lookupProperty(depth0,"realm_inline_url_embed_preview"),"prefix":"id_","setting_name":"realm_inline_url_embed_preview"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"organization-settings\" data-name=\"organization-settings\" class=\"settings-section\">\n    <form class=\"admin-realm-form org-settings-form\">\n\n        <div id=\"org-notifications\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Automated messages and emails",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":58}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-settings-parent\">\n                <div class=\"realm_default_language\">\n                    "
    + ((stack1 = container.invokePartial(require("./language_selection_widget.hbs"),depth0,{"name":"language_selection_widget","hash":{"help_link_widget_link":"/help/configure-organization-language","section_title":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_default_language"),"language_code":lookupProperty(depth0,"realm_default_language_code"),"setting_value":lookupProperty(depth0,"realm_default_language_name"),"section_name":"realm_default_language"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n\n                "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),depth0,{"name":"../dropdown_widget_with_label","hash":{"value_type":"number","label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_notifications_stream"),"widget_name":"realm_notifications_stream_id"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),depth0,{"name":"../dropdown_widget_with_label","hash":{"value_type":"number","label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_signup_notifications_stream"),"widget_name":"realm_signup_notifications_stream_id"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_message_content_allowed_in_email_notifications"),"is_checked":lookupProperty(depth0,"realm_message_content_allowed_in_email_notifications"),"prefix":"id_","setting_name":"realm_message_content_allowed_in_email_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_send_welcome_emails"),"is_checked":lookupProperty(depth0,"realm_send_welcome_emails"),"prefix":"id_","setting_name":"realm_send_welcome_emails"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"settings_send_digest_emails"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":47,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"input-group\">\n                    <label for=\"realm_digest_weekday\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Day of the week to send digests",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":77},"end":{"line":49,"column":117}}}))
    + "</label>\n                    <select name=\"realm_digest_weekday\"\n                      id=\"id_realm_digest_weekday\"\n                      class=\"setting-widget prop-element settings_select bootstrap-focus-style\"\n                      data-setting-widget-type=\"number\">\n                        <option value=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Monday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":42},"end":{"line":54,"column":57}}}))
    + "</option>\n                        <option value=\"1\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Tuesday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":42},"end":{"line":55,"column":58}}}))
    + "</option>\n                        <option value=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Wednesday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":42},"end":{"line":56,"column":60}}}))
    + "</option>\n                        <option value=\"3\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Thursday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":42},"end":{"line":57,"column":59}}}))
    + "</option>\n                        <option value=\"4\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Friday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":42},"end":{"line":58,"column":57}}}))
    + "</option>\n                        <option value=\"5\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Saturday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":42},"end":{"line":59,"column":59}}}))
    + "</option>\n                        <option value=\"6\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Sunday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":42},"end":{"line":60,"column":57}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-message-retention\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message retention",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":68,"column":46}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/message-retention-policy"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"message-retention"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n            "
    + ((stack1 = container.invokePartial(require("./upgrade_tip_widget.hbs"),depth0,{"name":"upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            <div class=\"inline-block organization-settings-parent\">\n                <div class=\"input-group time-limit-setting\">\n                    <label for=\"id_realm_message_retention_days\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message retention period",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":88},"end":{"line":78,"column":121}}}))
    + "\n                    </label>\n                    <select name=\"realm_message_retention_days\"\n                      id=\"id_realm_message_retention_days\" class=\"prop-element settings_select bootstrap-focus-style\"\n                      data-setting-widget-type=\"message-retention-setting\"\n                      "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"zulip_plan_is_not_limited"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":22},"end":{"line":83,"column":78}}})) != null ? stack1 : "")
    + ">\n                        <option value=\"unlimited\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retain forever",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":50},"end":{"line":84,"column":73}}}))
    + "</option>\n                        <option value=\"custom_period\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":54},"end":{"line":85,"column":69}}}))
    + "</option>\n                    </select>\n\n                    <div class=\"dependent-settings-block\">\n                        <label for=\"id_realm_message_retention_custom_input\" class=\"inline-block realm-time-limit-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retention period (days)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":28},"end":{"line":90,"column":60}}}))
    + ":\n                        </label>\n                        <input type=\"text\" id=\"id_realm_message_retention_custom_input\" autocomplete=\"off\"\n                          name=\"realm_message_retention_custom_input\"\n                          class=\"admin-realm-message-retention-days message-retention-setting-custom-input time-limit-custom-input\"\n                          data-setting-widget-type=\"number\"\n                          "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"zulip_plan_is_not_limited"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":26},"end":{"line":96,"column":82}}})) != null ? stack1 : "")
    + "/>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-other-settings\" class=\"settings-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Other settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":20},"end":{"line":104,"column":43}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"other-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-settings-parent\">\n                <div class=\"input-group\">\n                    <label for=\"realm_video_chat_provider\" class=\"dropdown-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Call provider",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":110,"column":24},"end":{"line":110,"column":46}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/start-a-call"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_video_chat_provider\" class =\"setting-widget prop-element settings_select bootstrap-focus-style\" id=\"id_realm_video_chat_provider\" data-setting-widget-type=\"number\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"realm_available_video_chat_providers"),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":24},"end":{"line":116,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n\n                    <div class=\"dependent-settings-block\" id=\"realm_jitsi_server_url_setting\">\n                        <div>\n                            <label for=\"id_realm_jitsi_server_url\" class=\"dropdown-title\">\n                                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Jitsi server URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":32},"end":{"line":122,"column":57}}}))
    + "\n                                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/start-a-call#configure-a-self-hosted-instance-of-jitsi-meet"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </label>\n                            <select name=\"realm_jitsi_server_url\" id=\"id_realm_jitsi_server_url\" class=\"setting-widget prop-element settings_select bootstrap-focus-style\" data-setting-widget-type=\"jitsi-server-url-setting\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"server_jitsi_server_url"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":126,"column":32},"end":{"line":132,"column":39}}})) != null ? stack1 : "")
    + "                                <option value=\"custom\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":133,"column":55},"end":{"line":133,"column":74}}}))
    + "</option>\n                            </select>\n                        </div>\n\n                        <div>\n                            <label for=\"id_realm_jitsi_server_url_custom_input\" class=\"jitsi_server_url_custom_input_label\">\n                                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":139,"column":32},"end":{"line":139,"column":44}}}))
    + "\n                            </label>\n                            <input type=\"text\" id=\"id_realm_jitsi_server_url_custom_input\" autocomplete=\"off\"\n                              name=\"realm_jitsi_server_url_custom_input\" class=\"realm_jitsi_server_url_custom_input settings_url_input\" maxlength=\"200\" />\n                        </div>\n                    </div>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"realm_giphy_rating\" class=\"dropdown-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"GIPHY integration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":148,"column":24},"end":{"line":148,"column":50}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":lookupProperty(depth0,"giphy_help_link")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_giphy_rating\" class =\"setting-widget prop-element settings_select bootstrap-focus-style\" id=\"id_realm_giphy_rating\" data-setting-widget-type=\"number\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"giphy_api_key_empty"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":190},"end":{"line":151,"column":232}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"giphy_rating_options"),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":24},"end":{"line":154,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n\n                "
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),depth0,{"name":"../dropdown_widget_with_label","hash":{"value_type":"string","label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_default_code_block_language"),"widget_name":"realm_default_code_block_language"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_mandatory_topics"),"is_checked":lookupProperty(depth0,"realm_mandatory_topics"),"prefix":"id_","setting_name":"realm_mandatory_topics"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label_parens_text":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_enable_read_receipts_parens_text"),"label":lookupProperty(lookupProperty(depth0,"admin_settings_label"),"realm_enable_read_receipts"),"is_checked":lookupProperty(depth0,"realm_enable_read_receipts"),"prefix":"id_","setting_name":"realm_enable_read_receipts"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"server_inline_image_preview"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":16},"end":{"line":184,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"server_inline_url_embed_preview"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":186,"column":16},"end":{"line":193,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});