var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"view_user_profile\" data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias1(depth0, "user_id", {"start":{"line":3,"column":54},"end":{"line":3,"column":61}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":3,"column":52},"end":{"line":3,"column":63}}}) : helper)))
    + "\" tabindex=\"0\" role=\"button\">\n            <img class=\"read_receipts_user_avatar\" src=\""
    + alias4((((helper = lookupProperty(helpers,"avatar_url") || alias1(depth0, "avatar_url", {"start":{"line":4,"column":58},"end":{"line":4,"column":68}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"avatar_url","hash":{},"data":data,"loc":{"start":{"line":4,"column":56},"end":{"line":4,"column":70}}}) : helper)))
    + "\" />\n            <span>"
    + alias4((((helper = lookupProperty(helpers,"full_name") || alias1(depth0, "full_name", {"start":{"line":5,"column":20},"end":{"line":5,"column":29}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"full_name","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":31}}}) : helper)))
    + "</span>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul id=\"read_receipts_list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"users"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":7,"column":13}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});