var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression((((helper = lookupProperty(helpers,"emoji_name") || container.strict(depth0, "emoji_name", {"start":{"line":3,"column":38},"end":{"line":3,"column":48}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emoji_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":50}}}) : helper)))
    + ":</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"still_url"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":11,"column":0}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img src=\""
    + alias4((((helper = lookupProperty(helpers,"still_url") || alias1(depth0, "still_url", {"start":{"line":5,"column":12},"end":{"line":5,"column":21}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"still_url","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":23}}}) : helper)))
    + "\" class=\"emoji status-emoji\" data-animated-url=\""
    + alias4((((helper = lookupProperty(helpers,"url") || alias1(depth0, "url", {"start":{"line":5,"column":73},"end":{"line":5,"column":76}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":5,"column":71},"end":{"line":5,"column":78}}}) : helper)))
    + "\" data-still-url=\""
    + alias4((((helper = lookupProperty(helpers,"still_url") || alias1(depth0, "still_url", {"start":{"line":5,"column":98},"end":{"line":5,"column":107}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"still_url","hash":{},"data":data,"loc":{"start":{"line":5,"column":96},"end":{"line":5,"column":109}}}) : helper)))
    + "\" />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"url"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":11,"column":0}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img src=\""
    + alias4((((helper = lookupProperty(helpers,"url") || alias1(depth0, "url", {"start":{"line":8,"column":12},"end":{"line":8,"column":15}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":17}}}) : helper)))
    + "\" class=\"emoji status-emoji\" data-animated-url=\""
    + alias4((((helper = lookupProperty(helpers,"url") || alias1(depth0, "url", {"start":{"line":8,"column":67},"end":{"line":8,"column":70}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":8,"column":65},"end":{"line":8,"column":72}}}) : helper)))
    + "\" />\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"emoji_code"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":11,"column":0}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"emoji status-emoji emoji-"
    + container.escapeExpression((((helper = lookupProperty(helpers,"emoji_code") || container.strict(depth0, "emoji_code", {"start":{"line":10,"column":40},"end":{"line":10,"column":50}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emoji_code","hash":{},"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":52}}}) : helper)))
    + "\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":12,"column":7}}})) != null ? stack1 : "");
},"useData":true});