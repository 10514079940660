var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tip\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"bot-settings-tip\" id=\"personal-bot-settings-tip\">\n        </div>\n        <div>\n            <button class=\"button rounded sea-green add-a-new-bot "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_create_new_bots"),{"name":"unless","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":66},"end":{"line":14,"column":112}}})) != null ? stack1 : "")
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add a new bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":114},"end":{"line":14,"column":136}}}))
    + "</button>\n        </div>\n        <div>\n            <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Download config of all active outgoing webhook bots in Zulip Botserver format.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":18},"end":{"line":17,"column":105}}}))
    + "</span>\n            <a type=\"submit\" download=\""
    + alias2((((helper = lookupProperty(helpers,"botserverrc") || container.strict(depth0, "botserverrc", {"start":{"line":18,"column":41},"end":{"line":18,"column":52}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"botserverrc","hash":{},"data":data,"loc":{"start":{"line":18,"column":39},"end":{"line":18,"column":54}}}) : helper)))
    + "\" id= \"download_botserverrc\" class=\"btn\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Download botserverrc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":102},"end":{"line":18,"column":131}}}))
    + "\">\n                <i class=\"fa fa-download sea-green\" aria-hidden=\"true\"></i>\n            </a>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                Looking for our <z-integrations>integrations</z-integrations> or <z-api>API</z-api> documentation?\n                \n                \n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/integrations/\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/api\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"7":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bot-settings\" class=\"settings-section\" data-name=\"your-bots\">\n    <div class=\"bot-settings-form\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"page_params"),"is_guest"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "\n        <ul class=\"nav nav-tabs nav-justified\" id=\"bots_lists_navbar\">\n            <li class=\"active active-bots-tab\"><a>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Active bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":50},"end":{"line":25,"column":70}}}))
    + "</a></li>\n            <li class=\"inactive-bots-tab\"><a>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Inactive bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":45},"end":{"line":26,"column":67}}}))
    + "</a></li>\n        </ul>\n\n        <ol class=\"bots_list\" id=\"active_bots_list\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have no active bots.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":64},"end":{"line":29,"column":97}}}))
    + "\">\n        </ol>\n\n        <ol class=\"bots_list\" id=\"inactive_bots_list\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have no inactive bots.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":66},"end":{"line":32,"column":101}}}))
    + "\">\n        </ol>\n\n    </div>\n</div>\n";
},"2_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-integrations"],"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":147}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-api"],"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":128}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});