var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        Archiving stream <z-stream></z-stream> will immediately unsubscribe everyone. This action cannot be undone.\n        \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong>"
    + ((stack1 = (((helper = lookupProperty(helpers,"stream_name_with_privacy_symbol_html") || container.strict(depth0, "stream_name_with_privacy_symbol_html", {"start":{"line":4,"column":42},"end":{"line":4,"column":78}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stream_name_with_privacy_symbol_html","hash":{},"data":data,"loc":{"start":{"line":4,"column":39},"end":{"line":4,"column":81}}}) : helper))) != null ? stack1 : "")
    + "</strong>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"notification_stream_archive_warning\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":47},"end":{"line":8,"column":150}}})) != null ? stack1 : "")
    + "</p>\n<ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_new_stream_notification_stream"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_signup_notification_stream"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "Archiving this stream will also disable settings that were configured to use this stream:";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":50}}})) != null ? stack1 : "")
    + "</li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "New stream notifications";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":48}}})) != null ? stack1 : "")
    + "</li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "New user notifications";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_notification_stream"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":17,"column":7}}})) != null ? stack1 : "");
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-stream"],"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":101}}}) || fn;
  return fn;
  }

,"useDecorators":true,"useData":true,"useDepths":true});