var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<template id=\"view-user-card-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"U",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":32}}}))
    + "\n</template>\n<template id=\"compose_draft_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":19}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"D",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":32}}}))
    + "\n</template>\n<template id=\"scroll-to-bottom-button-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scroll to bottom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":29}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"End",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":34}}}))
    + "\n</template>\n<template id=\"compose_reply_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply to selected message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":14,"column":38}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"R",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":32}}}))
    + "\n</template>\n<template id=\"compose_reply_selected_topic_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply to selected conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":18,"column":43}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"R",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":19,"column":32}}}))
    + "\n</template>\n<template id=\"compose_reply_button_disabled_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"You are not allowed to send direct messages in this organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":22,"column":78}}}))
    + "\n</template>\n<template id=\"left_bar_compose_mobile_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":25,"column":24}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"C",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":26,"column":32}}}))
    + "\n</template>\n<template id=\"new_topic_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":29,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"C",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":30,"column":32}}}))
    + "\n</template>\n<template id=\"new_stream_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New stream message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":33,"column":31}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"C",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":34,"column":32}}}))
    + "\n</template>\n<template id=\"new_direct_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":37,"column":31}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"X",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":38,"column":32}}}))
    + "\n</template>\n<template id=\"compose_close_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":4},"end":{"line":41,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Esc",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":42,"column":34}}}))
    + "\n</template>\n<template id=\"compose_close_and_save_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose and save draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":4},"end":{"line":45,"column":42}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Esc",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":46,"column":4},"end":{"line":46,"column":34}}}))
    + "\n</template>\n<template id=\"send-enter-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":49,"column":17}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":50,"column":36}}}))
    + "\n</template>\n<template id=\"send-ctrl-enter-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":4},"end":{"line":53,"column":17}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":54,"column":4},"end":{"line":54,"column":43}}}))
    + "\n</template>\n<template id=\"add-global-time-tooltip\">\n    <div>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add global time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":13},"end":{"line":58,"column":37}}}))
    + "</div>\n        <div class=\"tooltip-inner-content italic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Everyone sees global times in their own time zone.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":50},"end":{"line":59,"column":109}}}))
    + "</div>\n    </div>\n</template>\n<template id=\"add-poll-tooltip\">\n    <div>\n        <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add poll",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":14},"end":{"line":64,"column":31}}}))
    + "</span><br/>\n        <span class=\"tooltip-inner-content italic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"A poll must be an entire message.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":51},"end":{"line":65,"column":93}}}))
    + "</span>\n    </div>\n</template>\n<template id=\"delete-draft-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":4},"end":{"line":69,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Backspace",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":70,"column":4},"end":{"line":70,"column":40}}}))
    + "\n</template>\n<template id=\"restore-draft-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Restore draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":4},"end":{"line":73,"column":26}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":74,"column":4},"end":{"line":74,"column":36}}}))
    + "\n</template>\n<template id=\"gear-menu-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Main menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":4},"end":{"line":77,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"G",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":78,"column":4},"end":{"line":78,"column":32}}}))
    + "\n</template>\n<template id=\"personal-menu-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Personal menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":4},"end":{"line":81,"column":26}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"G","→",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":82,"column":4},"end":{"line":82,"column":36}}}))
    + "\n</template>\n<template id=\"help-menu-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Help menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":4},"end":{"line":85,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"G","←",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":86,"column":4},"end":{"line":86,"column":36}}}))
    + "\n</template>\n<template id=\"all-message-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"all_messages\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"All messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":13},"end":{"line":90,"column":34}}}))
    + "</div>\n        <div class=\"tooltip-inner-content views-tooltip-home-view-note italic hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is your home view.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":84},"end":{"line":91,"column":116}}}))
    + "</div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"A",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":93,"column":4},"end":{"line":93,"column":32}}}))
    + "\n</template>\n<template id=\"recent-conversations-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"recent_topics\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Recent conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":13},"end":{"line":97,"column":42}}}))
    + "</div>\n        <div class=\"tooltip-inner-content views-tooltip-home-view-note italic hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is your home view.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":84},"end":{"line":98,"column":116}}}))
    + "</div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"T",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":100,"column":4},"end":{"line":100,"column":32}}}))
    + "\n</template>\n<template id=\"inbox-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"inbox\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Inbox",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":13},"end":{"line":104,"column":27}}}))
    + "</div>\n        <div class=\"tooltip-inner-content views-tooltip-home-view-note italic hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is your home view.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":105,"column":84},"end":{"line":105,"column":116}}}))
    + "</div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","I",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":107,"column":4},"end":{"line":107,"column":40}}}))
    + "\n</template>\n<template id=\"drafts-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":110,"column":4},"end":{"line":110,"column":19}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"D",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":111,"column":4},"end":{"line":111,"column":32}}}))
    + "\n</template>\n<template id=\"show-all-pms-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"All direct messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":4},"end":{"line":114,"column":32}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","P",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":115,"column":4},"end":{"line":115,"column":40}}}))
    + "\n</template>\n<template id=\"mentions-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":4},"end":{"line":118,"column":21}}}))
    + "\n</template>\n<template id=\"starred-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Starred messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":121,"column":4},"end":{"line":121,"column":29}}}))
    + "\n</template>\n<template id=\"filter-streams-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":4},"end":{"line":124,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Q",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":125,"column":4},"end":{"line":125,"column":32}}}))
    + "\n</template>\n<template id=\"message-expander-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":128,"column":4},"end":{"line":128,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"-",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":129,"column":4},"end":{"line":129,"column":32}}}))
    + "\n</template>\n<template id=\"message-condenser-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show less",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":132,"column":4},"end":{"line":132,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"-",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":133,"column":4},"end":{"line":133,"column":32}}}))
    + "\n</template>\n<template id=\"edit-content-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":136,"column":4},"end":{"line":136,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"E",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":137,"column":4},"end":{"line":137,"column":32}}}))
    + "\n</template>\n<template id=\"move-message-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":140,"column":4},"end":{"line":140,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"M",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":141,"column":4},"end":{"line":141,"column":32}}}))
    + "\n</template>\n<template id=\"view-source-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View message source",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":144,"column":4},"end":{"line":144,"column":32}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"E",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":145,"column":4},"end":{"line":145,"column":32}}}))
    + "\n</template>\n<template id=\"add-emoji-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":148,"column":4},"end":{"line":148,"column":31}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,":",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":149,"column":4},"end":{"line":149,"column":32}}}))
    + "\n</template>\n<template id=\"message-actions-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":152,"column":4},"end":{"line":152,"column":28}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"I",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":153,"column":4},"end":{"line":153,"column":32}}}))
    + "\n</template>\n<template id=\"star-message-tooltip-template\">\n    <div class=\"starred-status\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Star this message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":156,"column":32},"end":{"line":156,"column":58}}}))
    + "</div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":157,"column":4},"end":{"line":157,"column":39}}}))
    + "\n</template>\n<template id=\"unstar-message-tooltip-template\">\n    <div class=\"starred-status\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unstar this message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":160,"column":32},"end":{"line":160,"column":60}}}))
    + "</div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":161,"column":4},"end":{"line":161,"column":39}}}))
    + "\n</template>\n<template id=\"search-query-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":164,"column":4},"end":{"line":164,"column":19}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"/",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":165,"column":4},"end":{"line":165,"column":32}}}))
    + "\n</template>\n<template id=\"streamlist-toggle-tooltip-template\" >\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":168,"column":4},"end":{"line":168,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Q",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":169,"column":4},"end":{"line":169,"column":32}}}))
    + "\n</template>\n<template id=\"userlist-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":172,"column":4},"end":{"line":172,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"W",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":173,"column":4},"end":{"line":173,"column":32}}}))
    + "\n</template>\n<template id=\"topic-unmute-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unmute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":176,"column":4},"end":{"line":176,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","M",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":177,"column":4},"end":{"line":177,"column":40}}}))
    + "\n</template>\n<template id=\"topic-mute-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":180,"column":4},"end":{"line":180,"column":23}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","M",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":181,"column":4},"end":{"line":181,"column":40}}}))
    + "\n</template>\n<template id=\"search-people-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search people",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":184,"column":4},"end":{"line":184,"column":26}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"W",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":185,"column":4},"end":{"line":185,"column":32}}}))
    + "\n</template>\n<template id=\"restore-scheduled-message-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit and reschedule message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":188,"column":4},"end":{"line":188,"column":40}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":189,"column":4},"end":{"line":189,"column":36}}}))
    + "\n</template>\n<template id=\"delete-scheduled-message-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete scheduled message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":192,"column":4},"end":{"line":192,"column":37}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Backspace",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":193,"column":4},"end":{"line":193,"column":40}}}))
    + "\n</template>\n<template id=\"create-new-stream-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create new stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":196,"column":4},"end":{"line":196,"column":30}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"N",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":197,"column":4},"end":{"line":197,"column":32}}}))
    + "\n</template>\n<template id=\"toggle-subscription-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Toggle subscription",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":200,"column":4},"end":{"line":200,"column":32}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":201,"column":4},"end":{"line":201,"column":40}}}))
    + "\n</template>\n<template id=\"view-stream-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":204,"column":4},"end":{"line":204,"column":24}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","V",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":205,"column":4},"end":{"line":205,"column":40}}}))
    + "\n</template>\n<template id=\"mobile-push-notification-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mobile push notifications are not enabled on this server.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":208,"column":4},"end":{"line":208,"column":70}}}))
    + "\n</template>\n";
},"useData":true});