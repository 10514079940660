var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_date_row"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"messagebox-content\">\n        <div class=\"message_top_line\"><span class=\"message_time\">"
    + alias4((((helper = lookupProperty(helpers,"timestamp") || alias2(depth0, "timestamp", {"start":{"line":8,"column":68},"end":{"line":8,"column":77}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"timestamp","hash":{},"data":data,"loc":{"start":{"line":8,"column":65},"end":{"line":8,"column":80}}}) : helper)))
    + "</span></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"topic_edited"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"stream_changed"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"body_to_render"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"message_author\"><div class=\"author_details\">"
    + alias4((((helper = lookupProperty(helpers,"edited_by_notice") || alias2(depth0, "edited_by_notice", {"start":{"line":18,"column":67},"end":{"line":18,"column":83}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"edited_by_notice","hash":{},"data":data,"loc":{"start":{"line":18,"column":64},"end":{"line":18,"column":86}}}) : helper)))
    + "</div></div>\n    </div>\n    <hr />\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"date_row\"><span>"
    + container.escapeExpression((((helper = lookupProperty(helpers,"display_date") || container.strict(depth0, "display_date", {"start":{"line":5,"column":35},"end":{"line":5,"column":47}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"display_date","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":50}}}) : helper)))
    + "</span></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"message_content message_edit_history_content\"><p>Topic: <span class=\"highlight_text_inserted\">"
    + alias4((((helper = lookupProperty(helpers,"new_topic") || alias1(depth0, "new_topic", {"start":{"line":10,"column":117},"end":{"line":10,"column":126}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"new_topic","hash":{},"data":data,"loc":{"start":{"line":10,"column":114},"end":{"line":10,"column":129}}}) : helper)))
    + "</span> <span class=\"highlight_text_deleted\">"
    + alias4((((helper = lookupProperty(helpers,"prev_topic") || alias1(depth0, "prev_topic", {"start":{"line":10,"column":177},"end":{"line":10,"column":187}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"prev_topic","hash":{},"data":data,"loc":{"start":{"line":10,"column":174},"end":{"line":10,"column":190}}}) : helper)))
    + "</span></p></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"message_content message_edit_history_content\"><p>Stream: <span class=\"highlight_text_inserted\">"
    + alias4((((helper = lookupProperty(helpers,"new_stream") || alias1(depth0, "new_stream", {"start":{"line":13,"column":118},"end":{"line":13,"column":128}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"new_stream","hash":{},"data":data,"loc":{"start":{"line":13,"column":115},"end":{"line":13,"column":131}}}) : helper)))
    + "</span> <span class=\"highlight_text_deleted\">"
    + alias4((((helper = lookupProperty(helpers,"prev_stream") || alias1(depth0, "prev_stream", {"start":{"line":13,"column":179},"end":{"line":13,"column":190}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"prev_stream","hash":{},"data":data,"loc":{"start":{"line":13,"column":176},"end":{"line":13,"column":193}}}) : helper)))
    + "</span></p></div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"message_content rendered_markdown message_edit_history_content\">"
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"body_to_render"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":16,"column":84},"end":{"line":16,"column":120}}}))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"edited_messages"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":21,"column":9}}})) != null ? stack1 : "");
},"useData":true});