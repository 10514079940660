var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_edit_notice\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Last edited {last_edit_timestr}.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":53},"end":{"line":2,"column":94}}}))
    + "\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"SAVING",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":19}}}))
    + "\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"moved"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":13,"column":0}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_edit_notice\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Last moved {last_edit_timestr}.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":93}}}))
    + "\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"MOVED",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":18}}}))
    + "\n</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_edit_notice\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Last edited {last_edit_timestr}.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":53},"end":{"line":10,"column":94}}}))
    + "\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"EDITED",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":19}}}))
    + "\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"msg"),"local_edit_timestamp"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"useData":true});