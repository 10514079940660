var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert\" id=\"dev_env_msg\"></div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tip\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            You must <z-link>configure your email</z-link> to access this feature.\n            \n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/demo-organizations#configure-email-for-demo-organization-owner\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"default"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":24},"end":{"line":39,"column":60}}})) != null ? stack1 : "")
    + " name=\"expires_in\" value=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":39,"column":88},"end":{"line":39,"column":98}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":39,"column":104},"end":{"line":39,"column":120}} ), depth0))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "selected";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <option name=\"custom_time_choice\" class=\"custom_time_choice\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option name=\"invite_as\" value=\""
    + alias3(alias2(alias1(lookupProperty(lookupProperty(depth0,"invite_as_options"),"moderator"), "code", {"start":{"line":62,"column":47},"end":{"line":62,"column":79}} ), depth0))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Moderators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":84},"end":{"line":62,"column":103}}}))
    + "</option>\n            <option name=\"invite_as\" value=\""
    + alias3(alias2(alias1(lookupProperty(lookupProperty(depth0,"invite_as_options"),"admin"), "code", {"start":{"line":63,"column":47},"end":{"line":63,"column":75}} ), depth0))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Organization administrators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":80},"end":{"line":63,"column":116}}}))
    + "</option>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option name=\"invite_as\" value=\""
    + alias1(container.lambda(container.strict(lookupProperty(lookupProperty(depth0,"invite_as_options"),"owner"), "code", {"start":{"line":66,"column":47},"end":{"line":66,"column":75}} ), depth0))
    + "\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Organization owners",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":80},"end":{"line":66,"column":108}}}))
    + "</option>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"select_default_streams new-style\">\n                <label class=\"checkbox display-block\">\n                    <input type=\"checkbox\" id=\"invite_select_default_streams\" checked=\"checked\" />\n                    <span></span>\n                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Default streams for this organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":20},"end":{"line":78,"column":65}}}))
    + "\n                </label>\n            </div>\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                    <label class=\"checkbox display-block\">\n                        <input type=\"checkbox\" name=\"stream\" value=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "stream_id", {"start":{"line":90,"column":70},"end":{"line":90,"column":79}} ), depth0))
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"default_stream"),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":26},"end":{"line":91,"column":72}}})) != null ? stack1 : "")
    + " />\n                        <span></span>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"invite_only"),lookupProperty(depth0,"is_web_public"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":93,"column":30},"end":{"line":93,"column":60}}}),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.program(25, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":93,"column":24},"end":{"line":96,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"name"),lookupProperty(depths[1],"notifications_stream"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":97,"column":30},"end":{"line":97,"column":63}}}),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":24},"end":{"line":99,"column":31}}})) != null ? stack1 : "")
    + "                    </label>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":93,"column":85},"end":{"line":93,"column":89}} ), depth0))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "                        #"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":95,"column":27},"end":{"line":95,"column":31}} ), depth0))
    + "\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i>("
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Receives new stream announcements",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":28},"end":{"line":98,"column":70}}}))
    + ")</i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"invite-user-form\">\n    <div class=\"setup-tips-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":72}}})) != null ? stack1 : "")
    + "\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"development_environment"),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "    <div class=\"input-group\">\n        <label>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"How would you like to invite users?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":15},"end":{"line":15,"column":59}}}))
    + "</label>\n        <div class=\"invite_type_radio_section prop-element\" id=\"invite-user\">\n            <div id=\"generate_multiuse_invite_radio_container\">\n                <label class=\"generate_multiuse_invite_radio_label\">\n                    <input type=\"radio\" id=\"generate_multiuse_invite_radio\" name=\"invite-user\" value=\"generate-multiuse-invite\" />\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generate invite link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":49}}}))
    + "\n                </label>\n            </div>\n            <div id=\"email_invite_radio_container\">\n                <label class=\"email_invite_radio_label\">\n                    <input type=\"radio\" id=\"email_invite_radio\" name=\"invite-user\" value=\"email-invite\" />\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send an email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":42}}}))
    + "\n                </label>\n            </div>\n        </div>\n        <div id=\"invitee_emails_container\">\n            <label for=\"invitee_emails\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emails (one on each line or comma-separated)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":40},"end":{"line":31,"column":93}}}))
    + "</label>\n            <textarea rows=\"2\" id=\"invitee_emails\" name=\"invitee_emails\" class=\"invitee_emails\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"One or more email addresses...",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":109},"end":{"line":32,"column":148}}}))
    + "\"></textarea>\n        </div>\n    </div>\n    <div class=\"input-group\">\n        <label for=\"expires_in\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invitation expires after",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":32},"end":{"line":36,"column":65}}}))
    + "</label>\n        <select id=\"expires_in\" class=\"invite-expires-in modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"expires_in_options"),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":40,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n        <p id=\"expires_on\"></p>\n        <div id=\"custom-invite-expiration-time\" class=\"dependent-settings-block\">\n            <label for=\"expires_in\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":36},"end":{"line":44,"column":56}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"custom-expiration-time\" id=\"custom-expiration-time-input\" class=\"custom-expiration-time inline-block\" value=\"\" maxlength=\"3\"/>\n            <select class=\"custom-expiration-time modal_select bootstrap-focus-style\" id=\"custom-expiration-time-unit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"time_choices"),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":49,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <p id=\"custom_expires_on\"></p>\n        </div>\n    </div>\n    <div class=\"input-group\">\n        <label for=\"invite_as\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Users join as",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":31},"end":{"line":55,"column":53}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("./help_link_widget.hbs"),depth0,{"name":"help_link_widget","hash":{"link":"/help/roles-and-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n        <select id=\"invite_as\" class=\"invite-as modal_select bootstrap-focus-style\">\n            <option name=\"invite_as\" value=\""
    + alias2(alias4(alias3(lookupProperty(lookupProperty(depth0,"invite_as_options"),"guest"), "code", {"start":{"line":59,"column":47},"end":{"line":59,"column":75}} ), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Guests",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":80},"end":{"line":59,"column":95}}}))
    + "</option>\n            <option name=\"invite_as\" selected=\"selected\" value=\""
    + alias2(alias4(alias3(lookupProperty(lookupProperty(depth0,"invite_as_options"),"member"), "code", {"start":{"line":60,"column":67},"end":{"line":60,"column":96}} ), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":101},"end":{"line":60,"column":117}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":12},"end":{"line":64,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_owner"),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":12},"end":{"line":67,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n    </div>\n    <div>\n        <label>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Streams they should join",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":15},"end":{"line":71,"column":48}}}))
    + "</label>\n        <div id=\"streams_to_add\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_select_default_streams_option"),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":81,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"invite-stream-controls\">\n                <button class=\"btn btn-link\" type=\"button\" id=\"invite_check_all_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Check all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":88},"end":{"line":83,"column":106}}}))
    + "</button> |\n                <button class=\"btn btn-link\" type=\"button\" id=\"invite_uncheck_all_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Uncheck all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":90},"end":{"line":84,"column":110}}}))
    + "</button>\n            </div>\n            <div id=\"invite-stream-checkboxes\" class=\"new-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"streams"),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":16},"end":{"line":101,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</form>\n";
},"6_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":190}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});